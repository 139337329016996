import { hasFeatureEnabled } from 'js/src/libs/server-utils';
import type { DAMConfig } from 'js/src/typings/global';

const Flags = {
    canUseCopywriter: (): boolean => {
        return hasFeatureEnabled('copywriter_opt_in');
    },
    canUseMediaGuidance: (): boolean => {
        return hasFeatureEnabled('media_guidance');
    },
    canUseStaffControls: (): boolean => {
        return hasFeatureEnabled('staff_controls');
    },
    canSeeDamTab: (): boolean => {
        if (!window.SERVER?.USER?.dam_configs) {
            return false;
        }
        const damConfigs = window.SERVER.USER.dam_configs;
        return damConfigs.some((damConfig: DAMConfig) => {
            if (damConfig.require_connection && damConfig.is_connected) {
                return true;
            }
            if (!damConfig.require_connection) {
                return true;
            }
            return false;
        });
    },
    canUseInternalBlueprints: () => {
        return hasFeatureEnabled('internal_blueprints');
    },
    canUploadLongVideos: () => {
        return hasFeatureEnabled('can_upload_long_videos');
    },
    canTranscodeInBackground: () => {
        return hasFeatureEnabled('transcode_in_background');
    },
    canUseLuminaryCanvasTextAsset: () => {
        return hasFeatureEnabled('luminary_canvas_text_asset');
    },
    canUsePublicBlueprints: () => {
        return hasFeatureEnabled('public_blueprints');
    },
    canUseText2Image: () => {
        return hasFeatureEnabled('text_two_image');
    },
    canUseGenerativeMeida: () => {
        return hasFeatureEnabled('generative_media_tab');
    },
    canSeeSavedTemplateBlueprintRebrand: () => {
        return hasFeatureEnabled('saved_template_blueprint_rebrand');
    },
};

export default Flags;
